// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, value, ...fieldProps }, fieldState: { error } }) => (
        <TextField
          {...fieldProps}
          inputRef={ref}
          fullWidth
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          error={!!error}
          helperText={error?.message}
          {...other}
          InputLabelProps={{
            ...other.InputLabelProps,
            sx: {
              '&.Mui-disabled': { color: 'text.input' },
            },
          }}
          InputProps={{
            ...other.InputProps,
            sx: {
              '& .MuiInputBase-input': {
                color: 'text.primary',
              },
            },
          }}
        />
      )}
    />
  );
}

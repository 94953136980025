import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }

  interface TablePropsSizeOverrides {
    customized: true;
  }

  interface TableCellClasses {
    sizeCustomized: true;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#d0fbd5',
  light: '#7bea9c',
  main: '#0CB586 ',
  dark: '#009969',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#EEF5FF',
  light: '#B4D4FF',
  main: '#637A9F',
  dark: '#0077b6',
  darker: '#14213D',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const TIXA_BLUE = {
  0: '#FFFFFF',
  100: '#dce2ef',
  200: '#b9c5df',
  300: '#96a8cf',
  400: '#738bbf',
  500: '#506eaf',
  600: '#40588c',
  700: '#304269',
  800: '#202c46',
  900: '#111827',
  500_8: alpha('#506eaf', 0.08),
  500_12: alpha('#506eaf', 0.12),
  500_16: alpha('#506eaf', 0.16),
  500_24: alpha('#506eaf', 0.24),
  500_32: alpha('#506eaf', 0.32),
  500_48: alpha('#506eaf', 0.48),
  500_56: alpha('#506eaf', 0.56),
  500_80: alpha('#506eaf', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: TIXA_BLUE[800] },
  warning: { ...WARNING, contrastText: TIXA_BLUE[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: TIXA_BLUE,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: TIXA_BLUE[500_24],
  action: {
    hover: TIXA_BLUE[500_8],
    selected: TIXA_BLUE[500_16],
    disabled: TIXA_BLUE[500_80],
    disabledBackground: TIXA_BLUE[500_24],
    focus: TIXA_BLUE[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: TIXA_BLUE[800],
      secondary: TIXA_BLUE[600],
      disabled: TIXA_BLUE[500],
      input: TIXA_BLUE[300],
    },
    background: { paper: '#f2f2f2', default: '#f2f2f2', neutral: TIXA_BLUE[100] }, // FIXME reset the background to white
    action: { active: TIXA_BLUE[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: TIXA_BLUE[100],
      secondary: TIXA_BLUE[300],
      disabled: TIXA_BLUE[300],
      input: TIXA_BLUE[600],
    },
    background: { paper: TIXA_BLUE[800], default: TIXA_BLUE[900], neutral: TIXA_BLUE[500_16] },
    action: { active: TIXA_BLUE[500], ...COMMON.action },
  },
} as const;

export default palette;

import { AlertColor, Backdrop, CircularProgress } from '@mui/material';
import { ReactNode, createContext, useState } from 'react';

type BackdropProviderProps = {
  children: ReactNode;
};

type BackdropContextProps = {
  onOpenBackdrop: VoidFunction;
  onCloseBackdrop: VoidFunction;
};

export type ShowSnackbarProps = {
  type: AlertColor;
  message: string;
  hideDuration?: number;
};

const initialState: BackdropContextProps = {
  onOpenBackdrop: () => {},
  onCloseBackdrop: () => {},
};

const BackdropContext = createContext(initialState);

function BakcdropProvider({ children }: BackdropProviderProps) {
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const onOpenBackdrop = () => setOpenBackdrop(true);

  const onCloseBackdrop = () => setOpenBackdrop(false);

  return (
    <BackdropContext.Provider
      value={{
        onOpenBackdrop,
        onCloseBackdrop,
      }}
    >
      <Backdrop
        open={openBackdrop}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
}

export { BakcdropProvider, BackdropContext };
